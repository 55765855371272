import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomePage} from './home/home.page';
import {AuthPage} from './auth/auth.page';
import {AddExpensePage} from './add-expense/add-expense.page';
import {PageNotFoundPage} from './page-not-found/page-not-found.page';
import {TrackMileagePage} from './track-mileage/track-mileage.page';
import {AdminMenuPage} from './admin-menu/admin-menu.page';
import {AuthGuard} from './auth/auth.guard';
import {AddExpenseTypePage} from './add-expense-type/add-expense-type.page';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'signin/:redirectUrl', component: AuthPage},
  { path: 'signin', component: AuthPage},
  { path: 'home', component: HomePage },
  { path: 'admin-menu', component: AdminMenuPage, canActivate: [AuthGuard]},
  { path: 'add-expense', component: AddExpensePage, canActivate: [AuthGuard] },
  { path: 'page-not-found', component: PageNotFoundPage },
  { path: 'track-mileage', component: TrackMileagePage, canActivate: [AuthGuard] },
  { path: 'add-expense-type', component: AddExpenseTypePage, canActivate: [AuthGuard] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
