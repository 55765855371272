import { Component, OnInit } from '@angular/core';
import {AmplifyService} from 'aws-amplify-angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertController, LoadingController} from '@ionic/angular';
import {SideTinzExpense} from '../st-model.service';

@Component({
  selector: 'app-add-expense',
  templateUrl: './add-expense.page.html',
  styleUrls: ['./add-expense.page.scss'],
})
export class AddExpensePage implements OnInit {
  expenseDetails: FormGroup;
  expenseTypes: any[] = [];
  constructor(private amplifyService: AmplifyService, private formBuilder: FormBuilder,
              private loadingController: LoadingController, private alertController: AlertController) { }

  ngOnInit() {
    this.expenseDetails = this.formBuilder.group({
      expenseType: ['', Validators.required],
      dateIncurred: ['', Validators.required],
      valueOfExpense: ['', Validators.required]
    });
    this.getExpenseTypes();
  }
  private async submitNewExpense() {
    const expenseDetailsData: SideTinzExpense = new SideTinzExpense();
    expenseDetailsData.expenseType = this.expenseDetails.value.expenseType;
    expenseDetailsData.dateIncurred = this.expenseDetails.value.dateIncurred;
    expenseDetailsData.valueOfExpense = this.expenseDetails.value.valueOfExpense;
    const savingMsg = 'Adding new Expense...';
    await this.presentLoading(savingMsg);
    const payload = { body: JSON.stringify(expenseDetailsData), headers: {}};
    this.amplifyService.api().post('AddExpense', '', payload).then( () => {
      this.loadingController.dismiss().then(() => {
        this.presentAlert('Expense Saved').then( () => {
          this.expenseDetails.reset();
        });
      });
    }).catch( error => {
      this.loadingController.dismiss().then(() => {
        console.log(error);
      });
    });
  }

  private getExpenseTypes() {
    this.amplifyService.api().post('GetExpenseTypes', '', null).then( response => {
      for (const item of response) {
        this.expenseTypes.push(item.name);
      }
    }).catch(error => console.log(error));
  }

  private async presentLoading(msg: string) {
    const loading = await this.loadingController.create({message: msg});
    return await loading.present();
  }

  private async presentAlert(msg: string) {
    const alert = await this.alertController.create({
      header: 'SideTinz',
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
  }
}
