import { Component, OnInit } from '@angular/core';

import {AmplifyService} from 'aws-amplify-angular';
import {LoadingController, NavController, NavParams} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.page.html',
    styleUrls: ['./auth.page.scss'],
})
export class AuthPage implements OnInit {
    loginDetails = {userName: '', password: ''};
    authenticationData: FormGroup;
    constructor(private amplifyService: AmplifyService, private navController: NavController,
                private loadingController: LoadingController, private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.authenticationData = this.formBuilder.group({
           'userName': ['layi.egbeyemi@ibrosoft.org', Validators.required],
           'password': ['', Validators.required]
        });
    }

    private async signIn() {
        const signingInMsg = 'Signing in ' + this.authenticationData.value.userName + ' ...';
        this.loginDetails.userName = this.authenticationData.value.userName;
        this.loginDetails.password = this.authenticationData.value.password;
        console.log(signingInMsg);
        await this.presentLoading(signingInMsg);
        this.amplifyService.auth().signIn(this.loginDetails.userName, this.loginDetails.password).then(() => {
                this.loadingController.dismiss().then();
                console.log('Logged in successfully');
            }).catch(err => {
            this.loadingController.dismiss().then();
            console.log(err.code, ' ' + err.message);
        });
    }

    private async presentLoading(msg: string) {
        const loading = await this.loadingController.create({message: msg});
        return await loading.present();
    }
}
