import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AmplifyService} from 'aws-amplify-angular';
import {SideTinzExpenseType} from '../st-model.service';
import {AlertController, LoadingController} from '@ionic/angular';

@Component({
  selector: 'app-add-expense-type',
  templateUrl: './add-expense-type.page.html',
  styleUrls: ['./add-expense-type.page.scss'],
})
export class AddExpenseTypePage implements OnInit {
  expenseType: FormGroup;
  constructor(private amplifyService: AmplifyService, private formBuilder: FormBuilder,
              private loadingController: LoadingController, private alertController: AlertController) { }

  ngOnInit() {
    this.expenseType = this.formBuilder.group({
      'name': ['', Validators.required]
    });
  }

  private async submitExpenseType() {
    const expenseTypeData = new SideTinzExpenseType();
    expenseTypeData.name = this.expenseType.value.name;
    const signingInMsg = 'Adding Expense Type ' + expenseTypeData.name + ' ...';
    await this.presentLoading(signingInMsg);
    const payload = {
      body: JSON.stringify(expenseTypeData),
      headers: {}
    };
    this.amplifyService.api().post('AddExpenseType', '', payload).then( () => {
      this.loadingController.dismiss().then(() => {
        this.presentAlert('Expense Type Saved').then( () => {
          this.expenseType.reset();
        });
      });
    }).catch( error => {
      this.loadingController.dismiss().then(() => {
        console.log(error);
      });
    });
  }

  private async presentLoading(msg: string) {
    const loading = await this.loadingController.create({message: msg});
    return await loading.present();
  }

  private async presentAlert(msg: string) {
    const alert = await this.alertController.create({
      header: 'SideTinz',
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
  }
}
