import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SideTinzUser {
  userName: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class SideTinzExpenseType {
  name: string;
  //  dateTest: Date;
}

@Injectable({
  providedIn: 'root'
})
export class SideTinzExpense {
  expenseType: string;
  dateIncurred: string;
  valueOfExpense: number;
}

@Injectable({
  providedIn: 'root'
})
export class SideTinzMileage {
  dateIncurred: Date;
  value: number;
}
