import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import {Auth, API} from 'aws-amplify';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

Auth.configure({
  identityPoolId: 'ca-central-1:18380cab-6212-4245-9478-ea3f20d53aae',
  region: 'ca-central-1',
  userPoolId: 'ca-central-1_SpsErmwDB',
  userPoolWebClientId: '2ckef3n24ojd3b4ams2sggpqgp'
});
API.configure({
  endpoints: [
    {
      name: 'AddExpenseType',
      endpoint: 'https://lambda.ca-central-1.amazonaws.com/2015-03-31/functions/st-add-expense-type/invocations',
      service: 'lambda',
      region: 'ca-central-1'
    },
    {
      name: 'GetExpenseTypes',
      endpoint: 'https://lambda.ca-central-1.amazonaws.com/2015-03-31/functions/st-get-expense-types/invocations',
      service: 'lambda',
      region: 'ca-central-1'
    },
    {
      name: 'AddExpense',
      endpoint: 'https://lambda.ca-central-1.amazonaws.com/2015-03-31/functions/st-add-expense/invocations',
      service: 'lambda',
      region: 'ca-central-1'
    },
    {
      name: 'TrackMileage',
      endpoint: 'https://lambda.ca-central-1.amazonaws.com/2015-03-31/functions/st-track-mileage/invocations',
      service: 'lambda',
      region: 'ca-central-1'
    }
  ]
});
