import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {HomePage} from './home/home.page';
import {AmplifyService} from 'aws-amplify-angular';
import {AuthPage} from './auth/auth.page';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddExpensePage} from './add-expense/add-expense.page';
import {TrackMileagePage} from './track-mileage/track-mileage.page';
import {PageNotFoundPage} from './page-not-found/page-not-found.page';
import {AdminMenuPage} from './admin-menu/admin-menu.page';
import {AddExpenseTypePage} from './add-expense-type/add-expense-type.page';

@NgModule({
  declarations: [AppComponent,
    HomePage,
    AuthPage,
    AddExpensePage,
    AddExpenseTypePage,
    TrackMileagePage,
    PageNotFoundPage,
    AdminMenuPage
  ],
  entryComponents: [],
  imports: [
      BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      AmplifyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
