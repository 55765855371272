import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AmplifyService } from 'aws-amplify-angular';
import {NavController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  signedIn: boolean;
  constructor(private navController: NavController, private amplifyService: AmplifyService) {
  }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.amplifyService.authStateChange$
      .subscribe(authState => {
        this.signedIn = authState.state === 'signedIn';
        console.log('state.url', state.url);
        console.log('authState.state', authState.state );
        console.log('AuthGuard - canActivate ', this.signedIn);
        if (!this.signedIn) {
            this.navController.navigateForward('/signin' + state.url);
        } else {
            this.navController.navigateForward(state.url);
        }
      });
    return this.signedIn;
  }
}
