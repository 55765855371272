import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AmplifyService} from 'aws-amplify-angular';
import {AlertController, LoadingController} from '@ionic/angular';
import {SideTinzMileage} from '../st-model.service';

@Component({
  selector: 'app-track-mileage',
  templateUrl: './track-mileage.page.html',
  styleUrls: ['./track-mileage.page.scss'],
})
export class TrackMileagePage implements OnInit {
  mileageDetails: FormGroup;
  constructor(private amplifyService: AmplifyService, private formBuilder: FormBuilder,
              private loadingController: LoadingController, private alertController: AlertController) { }

  ngOnInit() {
    this.mileageDetails = this.formBuilder.group({
      dateIncurred: ['', Validators.required],
      value: ['', Validators.required]
    });
  }

  private async submitMileage() {
    const trackMileageData: SideTinzMileage = new SideTinzMileage();
    trackMileageData.dateIncurred = this.mileageDetails.value.dateIncurred;
    trackMileageData.value = this.mileageDetails.value.value;
    const savingMsg = 'Adding new Mileage data...';
    await this.presentLoading(savingMsg);
    const payload = {
      headers: {},
      body: JSON.stringify(trackMileageData)
    };
    this.amplifyService.api().post('TrackMileage', '', payload).then( () => {
      this.loadingController.dismiss().then(() => {
        this.presentAlert('Mileage Saved').then( () => {
          this.mileageDetails.reset();
        });
      });
    }).catch( error => {
      this.loadingController.dismiss().then(() => {
        console.log(error);
      });
    });
  }

  private async presentLoading(msg: string) {
    const loading = await this.loadingController.create({message: msg});
    return await loading.present();
  }

  private async presentAlert(msg: string) {
    const alert = await this.alertController.create({
      header: 'SideTinz',
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
  }
}
