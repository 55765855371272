import {Component, OnInit} from '@angular/core';
import {AmplifyService} from 'aws-amplify-angular';
import {NavController} from '@ionic/angular';


@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit {

  isAuthenticated: boolean;
  amplifyService: AmplifyService;
  constructor(private amplify: AmplifyService, private navController: NavController) {
    this.amplifyService = amplify;
      this.amplifyService.authStateChange$
          .subscribe(authState => {
              this.isAuthenticated = authState.state === 'signedIn';
              console.log('authState.state', authState.state );
          });
  }
  ngOnInit(): void {
    console.log('isAuthenticated', this.isAuthenticated);
  }
  signOut() {
      this.amplifyService.auth().signOut({global: true})
          .then(() => {
              this.navController.navigateRoot('home');
          })
          .catch(err => console.log(err));
  }

}
